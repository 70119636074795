import React, { Component } from "react";
class About extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section id="about" className="s-about target-section">
        <div className="about-me">
          <div className="row heading-block" data-aos="fade-up">
            <div className="column large-full">
              <h2 className="section-heading">About Me</h2>
            </div>
          </div>

          <div className="row about-me__content" data-aos="fade-up">
            <div className="column large-full about-me__text">
              {this.props.activestatus ? (
                <div>
                  <p className="lead">
                    I have more than 15 years of professional experience as a QA
                    engineer, and have worked at both startups and large
                    companies.
                    <br></br>I'm looking for a test automation engineer role
                    where I can apply my technical expertise and bring value to
                    your company.
                  </p>
                </div>
              ) : (
                <div>
                  <p className="lead">
                    I have more than 15 years of professional experience as a QA
                    engineer, and have worked at both startups and large
                    companies.
                  </p>
                </div>
              )}
              <p>
                The fact that I have developer background helps me to reach
                advantage in automation of QA processes. <br></br>While I'm
                proficient in developing application, my deep understanding of
                CI/CD processes lets me achieve great results. Eliminating
                time-consuming manual testing processes especially regression
                testing makes more room for developing quality code. I use
                modern technologies such as Cypress, Mocha, Chai, Travis CI to
                satisfy higher requirements to quality of code.
                <br></br>I continuously stand and advocate DevOps culure shift
                for success of business.
              </p>

              <p>
                <br></br>
                <br></br>
                <strong> Recent projects:</strong> <br />
                <li>
                  Test automation of React.js application with WebDriver.io.
                </li>
                <li>
                  Jenkins pipeline for test automation of SaaS ASP.NET
                  application.
                </li>
                <li>
                  Installation and maintenance of JMeter performance server.
                </li>
                <li>Dockerization of WebDriver.io test suite.</li>
                <li>
                  This site is also my project, utilizing ReactJS, AWS Amplify,
                  Lambda, API Gateway and S3.
                </li>
              </p>

              <div id="personal-interests">
                <p>
                  <strong> My personal interests include:</strong> <br />
                  <li>
                    {" "}
                    Workouts in fitness club. Stay fit. Stay healthy. &#128170;
                  </li>
                  <li> Taking care of my awesome home pets. </li>
                  <li>
                    {" "}
                    Digital photography. =&gt;{" "}
                    <a href="http://torontoautoshow.serveblog.net/">
                      <span className="list-site">
                        <u>Some of my pictures.</u>
                      </span>
                    </a>
                  </li>
                  <li>
                    {" "}
                    Reading <em>
                      Data structures and algorithms in Java.
                    </em>{" "}
                    &#128522;{" "}
                  </li>
                  <li> Electronics hobbyist - Raspberry 4 projects. </li>
                  <li> Making great speeches at Toastmaster's meeting.</li>
                  <li> PlayStation video gaming helps me to de-stress.</li>
                  <li>
                    Process my vacation travel videos with <br></br>FinalCut Pro
                    &amp; AdobePriemier.
                  </li>
                  <li>
                    Solving problems on
                    <a href="https://www.hackerrank.com/"> HackerRank</a> keeps
                    my mind sharp.
                  </li>
                </p>
              </div>
            </div>
          </div>

          {this.props.activestatus ? (
            <div className="row about-me__buttons">
              <div className="column large-half tab-full" data-aos="fade-up">
                <a
                  href="#contact"
                  onClick={this.props.eventhrlink}
                  className="btn btn--stroke full-width"
                >
                  Hire Me
                </a>
              </div>

              <div className="column large-half tab-full" data-aos="fade-up">
                <a
                  href={this.props.resumelink}
                  onClick={this.props.eventcvlink}
                  className="btn btn--primary full-width"
                >
                  Download CV
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    );
  }
}

export default About;
