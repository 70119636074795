import React from "react";

class FeedBackForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      isVisible: false, //it is hidden initially
      apiResponse: "",
      errorMessage: "",
      status: "",
    };
  }
  resetResponse() {
    this.setState({ apiResponse: "" });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.resetResponse();

    const formData = new FormData(event.target);
    var object = {};
    formData.forEach((value, key) => (object[key] = value));
    var json = JSON.stringify(object);

    this.setState({ status: "sending" });
    fetch(
      "https://htumn7zc97.execute-api.us-east-1.amazonaws.com/prod/feedback",
      {
        method: "POST",
        headers: { "Content-Type": "application/json", "x-api-key": "mJ0hfbb4tQrvQGqT52ypacQGjEbsGxl4Sgz1hpVe" },
        body: json,
      }
    )
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        this.setState({ apiResponse: JSON.parse(data.body) });
        this.setState({ status: "success" });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }
  render() {
    return this.props.isfeedbackVisible ? (
      <section id="feedback" className="s-about target-section">
        <div className="row heading-block">
          <p style={{ fontsize: 30, color: "red" }}>
            <em>{this.state.apiResponse}</em>
          </p>
          <form onSubmit={this.handleSubmit} onClose={this.resetResponse}>
            <label htmlFor="username">Your name <span class="required">*</span></label>
            <input
              ref={(node) => (this.input = node)}
              required={true}
              id="username"
              name="username"
              type="text"
              placeholder="Enter text"
              autoFocus
              onFocus={e => e.currentTarget.select()}
            />

            <label htmlFor="email">Your email <span class="required">*</span></label>
            <input
              ref={(node) => (this.input2 = node)}
              required={true}
              id="email"
              name="email"
              type="email"
            />

            <label htmlFor="message">Message <span class="required">*</span></label>
            <textarea
              required={true}
              rows="10"
              cols="100"
              name="message"
              placeholder="Enter text"
            ></textarea>

            <button
              onClick={this.props.eventEmail}
              style={{ marginRight: 180, justifyContent: "center" }}
            >
              Close
            </button>
            <button
              disabled={
                this.state.status === "sending" ||
                this.state.status === "success"
              }
              style={{ marginRight: 180, justifyContent: "center" }}
            >
              Send
            </button>
          </form>
        </div>
      </section>
    ) : null;
  }
}
export default FeedBackForm;
