import React, { Component } from "react";

class Header extends Component {
  render() {
    return (
      <header className="s-header">
        <div className="header-logo">
          <a className="site-logo" href="index.html"></a>
        </div>

        <nav className="header-nav-wrap">
          <ul className="header-main-nav">
            <li>
              <a className="smoothscroll" 
                href="#about" 
                title="about">
                About
              </a>
            </li>
            <li>
              <a className="smoothscroll"
                href="#portfolio"
                title="portfolio">
                Work &amp; Education
              </a>
            </li>
            <li>
              <a className="smoothscroll" 
                href="#services"
                title="services">
                Capabilities
              </a>
            </li>
            <li>
              <a className="smoothscroll"
                href="#skills"
                title="skills">
                Skills Matrix
              </a>
            </li>
            <li>
              <a className="smoothscroll" 
                href="#contact" 
                title="contact me">
                Say Hi
              </a>
            </li>
          </ul>

          <ul className="header-social">
            <li>
              <a href="https://facebook.com/andrei.makovik">
                <i className="fab fa-facebook-f" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/andrei2m">
                <i className="fab fa-twitter" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/andrei-makovik-7521542">
                <i className="fab fa-linkedin" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </nav>

        <a className="header-menu-toggle" href="#">
          <span>Menu</span>
        </a>
      </header>
    );
  }
}

export default Header;
