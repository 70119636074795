import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="row">
        <div className="column large-full   ss-copyright">
          <span style={{ color: "white" }}>© Copyright Epitome 2019 </span>
          <span style={{ color: "white" }}>
            Design by <a href="https://www.styleshout.com/"> StyleShout</a>
          </span>
        </div>
        <div className="ss-go-top">
          <a
            className="smoothscroll"
            title="Back to Top"
            href="#top"
            style={{ position: "absolute", bottom: 20, right: 0, alignSelf: "flex-end" }}
          ></a>
        </div>
      </div>
    );
  }
}

export default Footer;
