import React, { Component } from "react";

class Contact extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <section id="contact" className="s-contact ss-dark target-section">
        <div className="row heading-block" data-aos="fade-up">
          <div className="column large-full">
            <h2 className="section-heading">Get In Touch</h2>
          </div>
        </div>

        <div className="row contact-main" data-aos="fade-up">
          <div className="column large-full">
            <p className="section-desc">
              I'm happy to connect, listen and help.
              <br></br>
              I'm available for hire.
              <br></br>
              Let's work together and build something awesome. Let's turn your
              idea to an even greater product.
              <br />
              <em>
                <a href="#feedback">
                  <button id="emailbtn"onClick={this.props.eventEmail}>Contact Me</button>
                </a>
              </em>
            </p>
          </div>
        </div>

        <div
          className="row contact-infos"
          data-aos="fade-up"
          data-aos-anchor=".contact-main"
        >
          {this.props.activestatus ? (
            <div className="column large-5 medium-full contact-phone">
              <h4>Call Me</h4>
              <a href={this.props.resumelink} onClick={this.props.eventcvlink}>
                {" "}
                <img
                  src="../images/icons/Phone-icon.png"
                  width="42"
                  height="42"
                ></img>
              </a>
            </div>
          ) : null}
          <div className="column large-7 medium-full contact-social">
            <h4>Social</h4>
            <ul>
              <li>
                <a href="https://facebook.com/andrei.makovik" title="Facebook">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://twitter.com/andrei2m" title="Twitter">
                  Twitter
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/andrei-makovik-7521542"
                  title="LinkedIn"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
