import React, { Component } from "react";

class Intro extends Component {
  render() {
    return (
      <section id="intro" className="s-intro target-section">
        <div className="row intro-content">
          <div
            style={{ textAlign: "left" }}
            className="column large-9 mob-full intro-text"
          >
            <h3>Hello, I'm Andrei Makovik</h3>
            <h1>
              Test Automation Engineer <br />
              and <br /> Java Developer <br />
              based In Toronto.
            </h1>
          </div>

          <div className="intro-scroll">
            <a href="#about" className="intro-scroll-link smoothscroll">
              Scroll For More
            </a>
          </div>
          <div className="intro-grid"></div>
          <div
            className="intro-pic"
            style={{ position: "absolute", backgroundSize: '100%', backgroundImage: `url(${this.props.mypicLink})` }}
          ></div>
        </div>
      </section>
    );
  }
}

export default Intro;
