import React, { Component } from "react";

class Services extends Component {
  render() {
    return (
      <section id="services" className="s-services ss-dark target-section">
        <div className="shadow-overlay"></div>

        <div
          className="row heading-block heading-block--center"
          data-aos="fade-up"
        >
          <div className="column large-full">
            <h2 className="section-heading section-heading--centerbottom">
              Capabilities
            </h2>

            <p className="section-desc">
              My passion and goal is to help you make your business standout.
            </p>
          </div>
        </div>

        <div className="row services-list block-large-1-3 block-medium-1-2 block-tab-full">
          <div className="column item-service" data-aos="fade-up">
            <div className="item-service__content">
              <h4 className="item-title">Automation</h4>
              <p>
                Experienced in Java, C#, Python, JavaScript, Ruby , PowerShell and UNIX
                Shell scripts. TDD and BDD tools: Cucumber, Junit 5, TestNG, RSpec, NUnit
              </p>
            </div>
          </div>

          <div className="column item-service" data-aos="fade-up">
            <div className="item-service__content">
              <h4 className="item-title">Jenkins Pipelines</h4>
              <p>
                Developed and maintained automated CI/CD pipelines for code
                deployment and testing. Optimized pipelines for parallel
                execution of tests.
              </p>
            </div>
          </div>

          <div className="column item-service" data-aos="fade-up">
            <div className="item-service__content">
              <h4 className="item-title">Web Testing</h4>
              <p>
                Experienced in UI testing with Selenium API, RobotFramework.
                <br></br>Proficient with <a href="https://www.cypress.io">Cypress</a>.
                <br></br>Performace testing with JMeter.
              </p>
            </div>
          </div>

          <div className="column item-service" data-aos="fade-up">
            <div className="item-service__content">
              <h4 className="item-title">AWS CI/CD</h4>
              <p>
                Amazon Web Services core technologies: ECS, EC2, S3, VPC,
                CodeCommit, CodeBuilder, CodeDeploy, API Gateway, CloudFormation.
              </p>
            </div>
          </div>

          <div className="column item-service" data-aos="fade-up">
            <div className="item-service__content">
              <h4 className="item-title">Backend Testing</h4>
              <p>
                RESTfull web services testing with<a href="https://rest-assured.io/"> REST-assured API</a> and
                <a href="https://webdriver.io/"> Webdriver.IO.</a>
              </p>
            </div>
          </div>

          <div className="column item-service" data-aos="fade-up">
            <div className="item-service__content">
              <h4 className="item-title">Databases</h4>
              <p>
                Proficient in all aspects of SQL programming, PL/SQL procedures,
                functions , triggers. <br></br>Experienced with DataWarehouse concepts,
                <a href="https://www.abinitio.com/en/"> Ab Initio</a> and <a href="https://www.informatica.com/"> Informatica ETL</a>.
              </p>
            </div>
          </div>
          <div className="ss-go-top">
            <a
              className="smoothscroll"
              title="Back to Top"
              href="#top"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                alignSelf: "flex-end",
              }}
            ></a>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;
