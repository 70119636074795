import React, { Component } from "react";
import styles from "../skills.css";

class Skills extends Component {
  constructor(props) {
    super(props);

    this.toggleGraph = this.toggleGraph.bind(this);
    this.state = {
      isGraphVisible: false,
      graphButtonText: "Skills proficiency graph",
      graphEvent: this.toggleGraph,
    };
  }

  toggleGraph = () => {
    this.setState({ isGraphVisible: !this.state.isGraphVisible });
    if (this.state.isGraphVisible)
      this.setState({ graphButtonText: "Skills proficiency graph" });
    else {
      {
        this.setState({ graphButtonText: "Close graph" });
      }
    }
  };

  render() {
    return (
      <section
        id="skills"
        className="s-skills target-section"
        data-aos="fade-up"
      >
        <h2
          className="section-heading section-heading--centerbottom"
          data-aos="fade-up"
        >
          Skills matrix
        </h2>
        <div className="row">
          <div className="column column-skills " data-aos="fade-up">
            <h2>Programming languages</h2>
            <p>
              My knowledge of programming languages is in the below table, but it is
              not limited by the table. For any additional information you may
              want to <a href="#contact">contact me. </a>I will be glad to answer
              your questions.
            </p>
            <div>
              <table className="skills-table">
                <thead>
                  <tr>
                    <th colSpan="2">Programming languages</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Java</td>
                    <td>12 years</td>
                  </tr>
                  <tr>
                    <td>JavaScript</td>
                    <td>5 years</td>
                  </tr>
                  <tr>
                    <td>C#</td>
                    <td>8 years</td>
                  </tr>
                  <tr>
                    <td>Python</td>
                    <td>5 years</td>
                  </tr>
                  <tr>
                    <td>Ruby</td>
                    <td>8 years</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table className="skills-table">
                <thead>
                  <tr>
                    <th colSpan="2">Operating Systems</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Unix</td>
                    <td>15 years</td>
                  </tr>
                  <tr>
                    <td>Linux</td>
                    <td>15 years</td>
                  </tr>
                  <tr>
                    <td>Windows</td>
                    <td>20 years</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="column column-skills" data-aos="fade-up">
            <h2>Frameworks &amp; Tools</h2>
            <p>
              {" "}
              Frameworks and tools are important part of automation experience.
              This is a list of frameworks that I'm  more than just familiar with. 
              It doesn't include tools/framework that were used sporadically.
              
            </p>
            <div>
              <table className="skills-table">
                <thead>
                  <tr>
                    <th colSpan="2">Frameworks &amp; Tools</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Selenium</td>
                    <td>8 years</td>
                  </tr>
                  <tr>
                    <td>Cypress</td>
                    <td>3 years</td>
                  </tr>
                  <tr>
                    <td>RestAssured</td>
                    <td>4 years</td>
                  </tr>
                  <tr>
                    <td>RobotFramework</td>
                    <td>4 years</td>
                  </tr>
                  <tr>
                    <td>Jenkins</td>
                    <td>4 years</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table className="skills-table">
                <thead>
                  <tr>
                    <th colSpan="2">Soft skills</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Scrum/Agile</td>
                    <td>5 years</td>
                  </tr>
                  <tr>
                    <td>Analytical skills</td>
                    <td>✔</td>
                  </tr>
                  <tr>
                    <td>Verbal/Communication</td>
                    <td>✔</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
       
       <div className="column" data-aos="fade-up">
          <br />

          <em>
            <a href="#graphs">
              <button
                id="graphbutton"
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "right",
                }}
                onClick={this.toggleGraph}
              >
                {this.state.graphButtonText}
              </button>
            </a>
          </em>
        </div>
        {this.state.isGraphVisible ? (
          <div id="graphs" className="bars" data-aos="fade-up">
            <ul className="skills">
              <li>
                <span className="bar-expand java"></span>
                <em>Java</em>
              </li>
              <li>
                <span className="bar-expand javascript"></span>
                <em>JavaScript</em>
              </li>
              <li>
                <span className="bar-expand csharp"></span>
                <em>C#</em>
              </li>
             <li>
                <span className="bar-expand python"></span>
                <em>Python</em>
              </li>
              <li>
                <span className="bar-expand selenium"></span>
                <em>Selenium</em>
              </li>
               <li>
                <span className="bar-expand restassured"></span>
                <em>RestAssured</em>
              </li>
              <li>
                <span className="bar-expand robotframework"></span>
                <em>RobotFramework</em>
              </li>
            </ul>
          </div>
        ) : null}
      </section>
    );
  }
}

export default Skills;
