import React, { Component } from "react";
import Amplify, { Storage } from "aws-amplify";
import Analytics from "@aws-amplify/analytics";
import awsconfig from "./aws-exports";
import "./App.css";
import Header from "./Components/Header";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Intro from "./Components/Intro";
import Services from "./Components/Services";
import FeedBackForm from "./Components/Feedback";
import Portfolio from "./Components/Portfolio";
import Skills from "./Components/Skills"
import Ajv from "ajv";
import Myschema from "./schemas.json";
const ajv = new Ajv();
const validate = ajv.compile(Myschema);
var resumeDataFile;
Amplify.configure(awsconfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.cvClick = this.cvClick.bind(this);
    this.hireClick = this.hireClick.bind(this);
    this.toggleFeedback = this.toggleFeedback.bind(this);
    this.getResumeData = this.getResumeData.bind(this);
    this.clearFeedback = React.createRef();
    this.state = {
      resumeLink: "",
      myPicLink: "",
      isfeedbackVisible: false,
      cvClickEvent: this.cvClick,
      hireClickEvent: this.hireClick,
      emailFeedbackEvent: this.toggleFeedback,
      resumeDataLink: "",
      resumeData: {},
      isActive: true,
    };
  }
  cvClick() {
    Analytics.record({
      name: "cvDownloadClick",
      attributes: { source: "About-CV", author: "Andy" },
    });
  }

  hireClick() {
    Analytics.record({
      name: "hireMeClick",
      attributes: { source: "About-Hire", author: "Andy" },
    });
  }

  getResumeLink() {
    Storage.get("Resume.pdf", {
      level: "public",
      download: false,
      cacheControl: "no-cache",
    })
      .then((result) => {
        this.setState({ resumeLink: result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getMyPicLink() {
    Storage.get("mypic.jpg", {
      level: "public",
      download: false,
    })
      .then((result) => {
        this.setState({ myPicLink: result });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleFeedback() {
    this.setState({ isfeedbackVisible: !this.state.isfeedbackVisible });
    this.clearFeedback.current.resetResponse();
  }

  getResumeData() {
    fetch(this.state.resumeDataLink)
      .then((res) => res.json())
      .then((data) => {
        this.setState({ resumeData: data.resume });
        const valid = validate(data);
        if (!valid) {
          if (process.env.NODE_ENV === "development") {
            alert("Error in json");
          }
          console.log(validate.errors);
        }
        process.env.NODE_ENV;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getResumeDataLink() {
    if (process.env.NODE_ENV === "development") {
      resumeDataFile = "resumeDataDev.json";
    } else {
      resumeDataFile = "resumeData.json";
    }
    Storage.get(resumeDataFile, {
      level: "public",
      contentType: "application/json",
      download: false,
    })
      .then((result) => {
        this.setState({ resumeDataLink: result });
        this.getResumeData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.getResumeDataLink();
    this.getResumeLink();
    this.getMyPicLink();

    if (process.env.REACT_APP_STATUS === "Active") {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: false });
    }
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Intro mypicLink={this.state.myPicLink} />
        <About
          resumelink={this.state.resumeLink}
          activestatus={this.state.isActive}
          eventhrlink={this.state.hireClickEvent}
          eventcvlink={this.state.cvClickEvent}
        />
        <Portfolio data={this.state.resumeData} />
        <Services />
        <Skills />
        <Contact
          resumelink={this.state.resumeLink}
          eventEmail={this.state.emailFeedbackEvent}
          activestatus={this.state.isActive}
        />
        <FeedBackForm
          isfeedbackVisible={this.state.isfeedbackVisible}
          eventEmail={this.state.emailFeedbackEvent}
          ref={this.clearFeedback}
        />
       <Footer />
      </div>
    );
  }
}

export default App;
