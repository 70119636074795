import React, { Component } from "react";
import parse from "html-react-parser";

class Portfolio extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.data.work) {
      var work = this.props.data.work.map(function (job) {
        const htmlString = job.description;
        return (
          <div key={job.company}>
            <div
              className="column large-half tab-full"
              data-aos="fade-up"
            ></div>
            <div className="timeline__block">
              <div className="timeline__bullet"></div>
              <div className="timeline__header">
                <p className="timeline__timeframe">{job.years}</p>
                <div key={job.company}>
                  <h3 className="item-title">{job.company}</h3>
                </div>
                <h5>{job.title}</h5>
                <div className="timeline__desc">
                  <div>{parse(htmlString)}</div>
                </div>
              </div>
            </div>
          </div>
        );
      });
      return (
        <section id="portfolio" className="s-about target-section">
          <div className="about-experience">
            <div className="row heading-block" data-aos="fade-up">
              <div className="column large-full">
                <h2 className="section-heading">Work &amp; Education</h2>
              </div>
            </div>

            <div className="row about-experience__timeline">
              <div className="column large-half tab-full" data-aos="fade-up">
                <div className="timeline">
                  <div className="timeline__icon-wrap">
                    <span className="timeline__icon timeline__icon--work"></span>
                  </div>
                  <div>{work}</div>
                </div>
              </div>

              <div className="column large-half tab-full" data-aos="fade-up">
                <div className="timeline">
                  <div className="timeline__icon-wrap">
                    <span className="timeline__icon timeline__icon--education"></span>
                  </div>

                  <div className="timeline__block">
                    <div className="timeline__bullet"></div>
                    <div className="timeline__header">
                      <p className="timeline__timeframe">
                        February 2017 - February 2018
                      </p>
                      <h3 className="item-title">Toastmasters International</h3>
                      <h5>Competent Communicator</h5>
                    </div>
                    <div className="timeline__desc">
                      <p>Communications and leadership skills.</p>
                    </div>
                  </div>

                  <div className="timeline__block">
                    <div className="timeline__bullet"></div>
                    <div className="timeline__header">
                      <p className="timeline__timeframe">
                        February 2014 - December 2014
                      </p>
                      <h3 className="item-title">
                        MLC College of Business Technology and Healthcare
                      </h3>
                      <h5>Certificate in System Administration.</h5>
                    </div>
                    <div className="timeline__desc">
                      <p>SAP NetWeaver Based Project</p>
                    </div>
                  </div>
                  <div className="timeline__block">
                    <div className="timeline__bullet"></div>
                    <div className="timeline__header">
                      <p className="timeline__timeframe">
                        September 1980 - June 1985
                      </p>
                      <h3 className="item-title">
                        Omsk Technical State University
                      </h3>
                      <h5>Bachelor of Science.</h5>
                    </div>
                    <div className="timeline__desc">
                      <p>Computer Science.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div id="portfolio-up" className="ss-go-top">
                <a
                  className="smoothscroll"
                  title="Back to Top"
                  href="#top"
                  style={{
                    position: "absolute",
                    bottom: 20,
                    right: 0,
                    alignSelf: "flex-end",
                  }}
                ></a>
              </div>
            </div>
          </div>
        </section>
      );
    }

    return (
      <section id="portfolio" className="s-about target-section">
        <div>Loading...</div>
      </section>
    );
  }
}

export default Portfolio;
