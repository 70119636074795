/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:32b7556d-a936-4c55-8b5b-659b468dd0a2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FmsdQP9aQ",
    "aws_user_pools_web_client_id": "141rih78ssve1h0uo1jkrevb3e",
    "oauth": {},
    "aws_user_files_s3_bucket": "portfolio-bucket-1",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "08907291825449ee855bcf3dc77c06e1",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiemailfeedbck",
            "endpoint": "https://htumn7zc97.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
